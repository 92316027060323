import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { UsernameContext } from "./UsernameContext";
import logo from "../images/logo.png";
import "../styles/Navbar.css";

const Navbar = () => {
  const { username, handleLogout } = useContext(UsernameContext);
  const logoutTimerRef = useRef(null);

  useEffect(() => {

    const timerVal = 20 * 60 * 1000; // 10 minutes in milliseconds

    const handleTimer = () => {
      if (localStorage.getItem("stay_logged_in") === 'false') {
        console.log('Logging out due to timeout');
        handleLogout();
      } else {
        console.log('Will refresh the screen and restart the timer');
        logoutTimerRef.current = setTimeout(() => {
          handleTimer();
        }, timerVal);
        window.location.reload();
      }
    };

    const startLogoutTimer = () => {
        logoutTimerRef.current = setTimeout(() => {
          handleTimer();
        }, timerVal);
    };

    const handleUserActivity = () => {
      clearTimeout(logoutTimerRef.current);
      startLogoutTimer();
    };

    startLogoutTimer();

    // Add event listeners for user activity
    document.addEventListener("mousemove", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);
    document.addEventListener("click", handleUserActivity);
    document.addEventListener("scroll", handleUserActivity);

    // Clean up the event listeners when component unmounts or when the user logs out
    return () => {
      clearTimeout(logoutTimerRef.current);
      document.removeEventListener("mousemove", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
      document.removeEventListener("click", handleUserActivity);
      document.removeEventListener("scroll", handleUserActivity);
    };
  }, [handleLogout]);

  return (
    <nav id="navigation">
      {username ? (
        <Link className="logo" to="/">
          <img className="logo-img" src={logo} alt="Rentalmatics logo" />
        </Link>
      ) : (
        <div className="logo">
          <img className="logo-img" src={logo} alt="Rentalmatics logo" />
        </div>
      )}
      {username && (
        <>
          <div className="links">
            <Link className="nav-link" to="/">
              <button >Home</button>
            </Link>
            <Link className="nav-link" to="/costs">
              <button >Costs</button>
            </Link>
            <Link className="nav-link" to="/weekly_reports">
              <button >Weekly Reports</button>
            </Link>
            <span className="username">{username}</span>
            <button onClick={handleLogout}>Logout</button>
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
